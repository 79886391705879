import pic from '../../../assets/images/self.jpg';
import './index.scss';

const Logo = () => {

  return (
    <div className="logo-container">
        <img src={pic} alt="pic"/>
    </div>
  );
};

export default Logo;
